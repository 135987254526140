import React, { useEffect, useState, useContext } from 'react';
import { toSvg } from 'jdenticon';
import axiosClient from '../services/axiosClient';
import { db } from '../services/db';
import prismClient from '../services/prismClient';

import { AppContext } from '../contexts/AppContext';

const OverlayRequestApprovalComponent = ({ request, close }: any) => {
  const { 					
    chatWindowSelected,
    setChatWindowSelected,
    accessToken,
    setAccessToken,
    identityKeys,
    setIdentityKeys,
    server,
    setServer,
    boxKeys,
    setBoxKeys,
    chats,
    setChats,
    selectedChat,
    setSelectedChat, }: any =
	useContext(AppContext);

  const api = axiosClient.init(server?.host, accessToken);

	const [avatar, setAvatar] = useState('');
	const [requestChatName, setRequestChatName]: any = useState('');

	useEffect(() => {
		setAvatar(toSvg(request.recipiantIsk, 100));
	});

	const acceptRequest = async () => {
		const prism: any = await prismClient.init();

		// Generate session keys
		const sessionMasterKeys: any = prism.generateSessionKeys();
		const { rx, tx } = prism.generateSharedSessionKeysRequest(
			sessionMasterKeys.pk,
			sessionMasterKeys.sk,
			request.receivedPublic
		);

		await db.chat.add({
			name: requestChatName,
			recipiantIsk: request.recipiantIsk,
      server: request.server,
			pk: sessionMasterKeys.pk,
			sk: sessionMasterKeys.sk,
			sendCount: 0,
			tx: tx,
			rx: rx,
			newMessage: false,
		});

		let layer1_encrypt: any = prism.layer1_encrypt({
			session: sessionMasterKeys.pk,
			server: server?.host
		}, null, request.recipiantIsk, "KXA", 0);
		let layer2_encrypt: any = prism.layer2_encrypt(layer1_encrypt.cipher, layer1_encrypt.nonce);
		let layer3_encrypt: any = prism.layer3_encrypt(layer2_encrypt.cipher, layer2_encrypt.nonce, layer2_encrypt.key, request.recipiantIsk);

		await db.request.delete(request.recipiantIsk);

		await api.post('/message', {
			to: request.recipiantIsk,
			data: `${layer3_encrypt.keyCipher}:${layer3_encrypt.dataCipher}`,
		});

		setRequestChatName('');
		close();
	};

	const declineRequest = async () => {
		await db.request.delete(request.recipiantIsk);
		setRequestChatName('');
		close();
	};
	return (
		<>
			<p className="font-bold	text-3xl">Request</p>
			<div>
				<img
					className="p-1 w-20 mx-auto"
					src={`data:image/svg+xml;utf8,${encodeURIComponent(avatar)}`}
					alt="avatar"
				/>
				<p className="break-words">{request.recipiantIsk}</p>
			</div>
			<div>
				<input
					className="input"
					placeholder="New Chat Name"
					type="text"
					value={requestChatName}
					onChange={(e: any) => {
						setRequestChatName(e.target.value);
					}}
				/>
			</div>
			<div className="flex flex-row justify-end space-x-5 border-t-2 border-zinc-800 pt-3">
				<button
					onClick={() => {
						acceptRequest();
					}}
				>
					Accept
				</button>
				<button
					onClick={() => {
						declineRequest();
					}}
				>
					Decline
				</button>
				<button
					onClick={() => {
						close();
					}}
				>
					Close
				</button>
			</div>
		</>
	);
};

export default OverlayRequestApprovalComponent;
