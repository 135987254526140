import { db } from './db';
import axiosClient from './axiosClient';
import prismClient from './prismClient';

const get = async (baseURL: string, accessToken: string) => {
  const api = axiosClient.init(baseURL, accessToken);
	const prism: any = await prismClient.init();
	const newChats = await api.get('/message');

	newChats.data.messages.forEach((message: any) => {

		const [keyCipher, dataCipher] = message.message.split(':');

		let layer3_decrypt: any = prism.layer3_decrypt(keyCipher, dataCipher);
		let layer2_decrypt: any = prism.layer2_decrypt(layer3_decrypt.cipher, layer3_decrypt.key, layer3_decrypt.nonce);
		let layer1_decrypt: any = prism.layer1_decrypt(layer2_decrypt.data, layer2_decrypt.nonce, layer2_decrypt.from);

		switch (layer1_decrypt.type) {
			case 'KXR': // initial communication
				processMessage_KXR(layer2_decrypt.from, layer1_decrypt.data);
				break;
			case 'KXA': // response communication
				processMessage_KXA(layer2_decrypt.from, layer1_decrypt.data);
				break;
			case 'M': // new message
				processMessage_M(layer2_decrypt.from, layer1_decrypt);
				break;
			default:
				processMessage_UNKNOWN(layer2_decrypt.from);
		}
	});
};

const processMessage_KXR = async (from: string, data: any) => {
	await db.request.add({
		recipiantIsk: from,
    server: data.server,
		receivedPublic: data.session,
	});
};

const processMessage_KXA = async (from: string, data: any) => {
	const prism: any = await prismClient.init();
	const chatRecord: any = await db.chat.where('recipiantIsk').equals(from).first();
	const { rx, tx } = prism.generateSharedSessionKeysResponse(
		chatRecord.pk,
		chatRecord.sk,
		data.session
	);
	await db.chat.update(from, {
		rx: rx,
		tx: tx,
	});
};

const processMessage_M = async (from: string, data: any) => {
	const prism: any = await prismClient.init();
	const chatRecord: any = await db.chat.where('recipiantIsk').equals(from).first();

	const derivedReceiveKey = prism.sessionKeyDerivation(
		chatRecord.rx,
		data.cnt
	);

	const decryptedData = prism.layer0_decrypt(
		data.data,
		derivedReceiveKey,
		data.nonce
	);

	await db.message.add({
		recipiantIsk: chatRecord.recipiantIsk,
		date: data.date,
		type: 'M',
		data: decryptedData.message,
		sent: false,
	});

	await db.chat.update(chatRecord.recipiantIsk, {
		newMessage: true,
	});

	console.log('New Message Received: ', {
		from: from,
		message: decryptedData.message,
	});
};

const processMessage_UNKNOWN = async (from: string) => {
	console.log(`Unknown message type from: ${from}`);
};

export const messageUtils = {
	get: get,
};
